@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-heading: "Manrope Variable", "Manrope", "Inter", system-ui,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";

    --font-sans: "Inter", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";

    --font-black: "Rubik Mono One", sans-serif;

    --brand-25: 222, 100%, 98%;
    --brand-50: 219, 100%, 97%;
    --brand-100: 221, 100%, 94%;
    --brand-200: 223, 96%, 89%;
    --brand-300: 224, 96%, 82%;
    --brand-400: 228, 91%, 74%;
    --brand-500: 233, 86%, 67%;
    --brand-600: 237, 77%, 59%;
    --brand-700: 239, 60%, 51%;
    --brand-800: 238, 57%, 41%;
    --brand-900: 236, 49%, 34%;
    --brand-950: 236, 49%, 24%;

    --primary-foreground: 144, 68%, 85%;
    --primary-50: 144, 100%, 97%;
    --primary-100: 144, 82%, 92%;
    --primary-200: 144, 64%, 81%;
    --primary-300: 144, 51%, 66%;
    --primary-400: 144, 46%, 49%;
    --primary-500: 144, 61%, 39%;
    --primary-600: 144, 75%, 32%;
    --primary-700: 144, 79%, 27%;
    --primary-800: 144, 72%, 24%;
    --primary-900: 144, 68%, 20%;

    --secondary-25: 165, 80%, 98%;
    --secondary-50: 162, 76%, 97%;
    --secondary-100: 165, 85%, 89%;
    --secondary-200: 166, 84%, 78%;
    --secondary-300: 169, 76%, 64%;
    --secondary-400: 170, 65%, 50%;
    --secondary-500: 171, 79%, 40%;
    --secondary-600: 173, 83%, 32%;
    --secondary-700: 173, 76%, 26%;
    --secondary-800: 174, 68%, 22%;
    --secondary-900: 174, 61%, 19%;
    --secondary-950: 174, 61%, 10%;

    --scale-25: 240, 20%, 99%;
    --scale-50: 210, 40%, 98%;
    --scale-100: 210, 31%, 95%;
    --scale-200: 215, 27%, 91%;
    --scale-300: 213, 22%, 84%;
    --scale-400: 215, 13%, 65%;
    --scale-500: 215, 12%, 47%;
    --scale-600: 217, 15%, 35%;
    --scale-700: 216, 21%, 27%;
    --scale-800: 218, 28%, 17%;
    --scale-900: 219, 36%, 11%;
    --scale-950: 220, 37%, 8%;

  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading);
    font-weight: 500;
    /*color: var(--foreground);*/
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
